import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import {Link} from 'gatsby'
import NewsCard from './NewsCard'
import StrapiNewsCard from './StrapiNewsCard'

import moment from 'moment'

const BillPageTemplate = ({ title, notice, content, contentComponent, posts, strapiPosts }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  const filteredPosts = posts.filter((value, index, array) => {
    if(value.node.frontmatter.autoArchive === true){
      if(new moment(value.node.frontmatter.date).isAfter(new moment().subtract(6, 'months'))) {
        return true;
      } else {
        return false;
      }
    } else if(value.node.frontmatter.expires === null) {
      return true;
    } else if(new Date(value.node.frontmatter.expires).getTime() >= new Date().getTime()) {
      return true;
    } else {
      return false;
    }
  })

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PageContent className="content" content={content} />
            </div>
            <div className="news-preview-container">
              {strapiPosts.map(strapiPost =>(
                <StrapiNewsCard post={strapiPost} />
              ))}
              {filteredPosts.map(({ node: post }) =>(
                <NewsCard key={post.id} post={post} />
              ))}
              </div>
              <div style={{marginTop: '2rem'}} className="has-text-centered">
                <hr />
                <p><strong>You've reached the end. <Link to="/archive">View News Archive</Link></strong></p>
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}

BillPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BillPageTemplate
